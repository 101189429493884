import type { ReactElement } from 'react';
import { useState, memo } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'next-i18next';
import type { RuleSet } from 'styled-components';
import { media } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { Button } from '../../core/button/Button';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import type { GlobalWishlist as GlobalWishlistProps } from '../../../../amplienceTypes/schemas/imported/global-wishlist-schema';
import { useWishlistContext } from '../../../../utilities/context/dynamic/WishlistContext';
import type { WishlistProduct } from '../../../../utilities/graphql/codegen';
import { CustomerType, useRemoveFromWishlistMutation } from '../../../../utilities/graphql/codegen';
import { fetchCommonData } from '../../../../utilities/graphql/queries';
import { useUserContext } from '../../../../utilities/context/dynamic/UserContext';
import { MiniWishlistItem } from './MiniWishlistItem';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import logger from '../../../../utilities/logger';
import { SiteType } from '~/utilities/graphql/codegen';
import { useApolloContext } from '~/campaign/core/context/ApolloContext';

interface WishlistProps {
  wishlistHovered: boolean;
  onWishlistHover: (hovered: boolean) => void;
}

export const style = {
  GStarEmptyGrid: css`
    padding: 0 40px;
  `,

  AafEmptyGrid: css`
    padding: 0 ${({ theme }) => theme.size[4]};
  `,
};

export const EmptyGridStyle: Record<SiteType, RuleSet<object>> = {
  [SiteType.Outlet]: style.GStarEmptyGrid,
  [SiteType.Employeeshop]: style.GStarEmptyGrid,
  [SiteType.Aaf]: style.AafEmptyGrid,
  [SiteType.Gstar]: style.GStarEmptyGrid,
};

const S = {
  Wishlist: styled.div<{ $hovered: boolean; $isGStar: boolean }>`
    display: none;

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      display: block;
      background: ${({ theme }) => theme.colors.content.component.header.modal.base};
      position: absolute;
      right: 0;
      z-index: -1;
      box-sizing: border-box;
      width: 480px;
      padding: 24px 0;
      visibility: ${({ $hovered }) => ($hovered ? 'visible' : 'hidden')};
      transform: ${({ $hovered }) => ($hovered ? 'translateY(0)' : 'translateY(-900px)')};
      transition: visibility 0.2s linear, transform 0.2s linear;
      ${({ $isGStar }) =>
        !$isGStar &&
        css`
          border-top: 1px solid ${({ theme }) => theme.colors.border.subtle};
          border-bottom: 1px solid ${({ theme }) => theme.colors.border.subtle};
        `}
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  ItemsWrapper: styled.div`
    max-height: 330px;
    overflow: hidden visible;
    padding: 0 32px 0 40px;
  `,

  EmptyGrid: styled.div`
    ${({ theme }) => EmptyGridStyle[theme.siteType]}
  `,

  EmptyTitle: styled(Typography)<{ $isGStar: boolean }>`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    text-transform: ${({ $isGStar }) => ($isGStar ? 'none' : 'uppercase')};
    font-weight: ${({ $isGStar }) => ($isGStar ? 'bold' : 'normal')};
    font-size: 16px;
    line-height: 1em;
    margin-top: ${({ $isGStar }) => ($isGStar ? ({ theme }) => theme.size[3] : 0)};
  `,

  SignInText: styled(Typography)<{ $isGStar: boolean }>`
    color: ${({ theme }) => theme.colors.content.component.header.modal.color};
    font-size: 14px;
    margin: ${({ $isGStar }) =>
      $isGStar
        ? ({ theme }) => `${theme.size[4]} 0 ${theme.size[8]}`
        : ({ theme }) => `${theme.size[4]} 0 ${theme.size[6]}`};
  `,

  SignIn: styled(Button)`
    margin-bottom: ${({ theme }) => theme.size[3]};
    width: 100%;
    line-height: 40px;
    text-transform: none;
  `,

  CreateAccount: styled(Button)`
    width: 100%;
    line-height: 40px;
    text-transform: none;
  `,

  WishlistBtnWrapper: styled.div`
    margin: 8px 40px 0;
    line-height: 1.4;
  `,

  WishlistBtn: styled(Button)`
    line-height: 40px;
    width: 100%;
    text-transform: ${({ theme }) => (theme.siteType === SiteType.Gstar ? 'none' : 'uppercase')};
    font-size: ${({ theme }) =>
      theme.siteType === SiteType.Gstar ? '14px' : ({ theme }) => theme.size[3]};
  `,
};

const MiniWishlistComponent = ({
  wishlistHovered,
  onWishlistHover,
  emptyWishlist,
  signInText,
  signIn,
  createAccount,
  viewWishlist,
}: WishlistProps & GlobalWishlistProps): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalWishlist' });
  const { locale, siteType, isGStar } = useAppContext();
  const { client: apolloClient } = useApolloContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { miniWishlist, fetchWishlist } = useWishlistContext();
  const { user } = useUserContext();
  const {
    configuration: { lowestPriceDisplay },
  } = useStaticContext();
  const [removing, setRemoving] = useState<{ [k: string]: boolean }>({});
  const [removeFromWishlistMutation] = useRemoveFromWishlistMutation();
  const hideRPText = lowestPriceDisplay === 'NEVER';
  const removeFromWishlist = async (wishlistProduct?: WishlistProduct | null) => {
    if (!wishlistProduct?.code) {
      return;
    }

    setRemoving({ [wishlistProduct.code]: true });

    try {
      const commonData = await fetchCommonData({ siteType, locale, apolloClient });
      const wishlistData = (
        await removeFromWishlistMutation({
          variables: {
            siteType,
            code: wishlistProduct.code,
            locale,
            csrfToken: commonData?.csrfToken,
          },
        })
      ).data;

      if (wishlistData?.removeFromWishlist === true) {
        await fetchWishlist(true);
        pushToDataLayer({
          events: {
            category: 'wishlist',
            action: 'remove',
            label: wishlistProduct.code,
          },
          event: 'wishlist-remove',
        });
      }
    } catch (e) {
      logger.error(`Error fetching wishlist: ${e}`);
    }

    setRemoving({ [wishlistProduct.code]: false });
  };

  const emptyWishlistEl = (
    <S.EmptyGrid data-testid="mini-wishlist-empty">
      <S.EmptyTitle
        $isGStar={isGStar}
        component="h4"
        variant="h3"
        testId="mini-wishlist-empty-title"
      >
        {emptyWishlist || t('emptyWishlist')}
      </S.EmptyTitle>
      {user?.customerType !== CustomerType.Registered && isGStar && (
        <>
          <S.SignInText $isGStar={isGStar} component="p" variant="body">
            {signInText || t('signInText')}
          </S.SignInText>
          <S.SignIn
            ordinal="success"
            label={signIn || t('signIn')}
            href="/account/create"
            testId="mini-wishlist-login"
            hardReload
          />
          <S.CreateAccount
            ordinal="secondary"
            label={createAccount || t('createAccount')}
            href="/account/register"
            testId="mini-wishlist-register"
            hardReload
          />
        </>
      )}
    </S.EmptyGrid>
  );

  const fullWishlistEl = (
    <>
      <S.ItemsWrapper data-testid="mini-wishlist">
        {miniWishlist?.products?.map((wishlistProduct, i) => (
          <MiniWishlistItem
            key={i}
            product={wishlistProduct}
            index={i}
            removing={removing}
            removeFromWishlist={removeFromWishlist}
            hideRRPText={hideRPText}
          />
        ))}
      </S.ItemsWrapper>
      <S.WishlistBtnWrapper data-testid="wishlist-view">
        <S.WishlistBtn
          ordinal="success"
          label={viewWishlist || t('viewWishlist')}
          href="/wishlist"
          data-cs-capture=""
          hardReload
        />
      </S.WishlistBtnWrapper>
    </>
  );

  return (
    <S.Wishlist
      $isGStar={isGStar}
      $hovered={wishlistHovered}
      onMouseEnter={() => onWishlistHover(true)}
      onMouseLeave={() => onWishlistHover(false)}
      id="wishlistHeader"
      data-cs-capture=""
    >
      {miniWishlist?.products?.length ? fullWishlistEl : emptyWishlistEl}
    </S.Wishlist>
  );
};

export const MiniWishlist = memo(MiniWishlistComponent, isEqual);
