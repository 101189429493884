import { useCallback, useEffect, useState, useRef } from 'react';
import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { Title as USPItemSchema } from '../../../amplienceTypes/schemas/exported/usp-item-schema';
import type {
  TimeZone,
  Title as USPCountdownSchema,
} from '../../../amplienceTypes/schemas/exported/usp-countdown-schema';
import type {
  Title as UspList,
  ColorScheme,
} from '../../../amplienceTypes/schemas/exported/usp-list-schema';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { useMediaQueryContext } from '../../../utilities/context/dynamic/MediaQueryContext';
import { useUserContext } from '../../../utilities/context/dynamic/UserContext';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { useGalleryContext } from '../../../utilities/context/dynamic/GalleryContext';
import { colors } from '../core/styles';
import { USPItemContainer } from './USPItemContainer';
import { USPItem } from './USPItem';
import { USPCountdown } from './USPCountdown';
import {
  USP_ANIMATION_DURATION,
  USP_MESSAGE_DISPLAY_TIME,
  USP_BAR_DEFAULT_COLOR_SCHEME,
  timeOffsets,
} from '../../../utilities/constants/usp';
import { getPpeJourneyContent } from '../../../utilities/helpers';
import { SiteType } from '~/utilities/graphql/codegen';

const fullCycleTime = USP_ANIMATION_DURATION + USP_MESSAGE_DISPLAY_TIME + USP_ANIMATION_DURATION;

const S = {
  Wrapper: styled.div<{
    $colorScheme?: ColorScheme;
    $hasStickyProductImageGallery?: boolean;
    $isVisible?: boolean;
  }>`
    ${({ $hasStickyProductImageGallery, $colorScheme }) =>
      ($hasStickyProductImageGallery || $colorScheme === 'PPE') &&
      css`
        position: sticky;
        top: var(--top-nav-height);
        z-index: 1000;
      `}

    ${({ $colorScheme, theme }) =>
      theme.siteType === SiteType.Gstar &&
      $colorScheme === 'Light (default)' &&
      css`
        box-sizing: border-box;
        border-bottom: 1px solid ${theme.colors.border.mid};
      `}

    opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
    transition: ${({ $isVisible }) => ($isVisible ? 'all 0.2s ease-in-out' : 'unset')};
    height: var(--usp-bar-height);
    width: 100%;
    text-align: center;
    overflow: hidden;
    display: block;
    background-color: ${colors.WHITE};

    ${({ $colorScheme }) =>
      ($colorScheme === 'Black Friday' &&
        css`
          background-color: ${colors.BLACK};
        `) ||
      ($colorScheme === 'Dark' &&
        css`
          background-color: ${colors.NERO_GREY};
        `) ||
      ($colorScheme === 'PPE' &&
        css`
          background-color: ${colors.RRP_RED};
        `)}

    &.zeroHeight {
      height: 0;
    }

    #container:has(.ppeBanner) &:not(.sticky) {
      display: none;
    }
  `,

  USPBarList: styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
  `,
};

export const HeaderUSP = ({
  usp,
  colorScheme: initialColorScheme = USP_BAR_DEFAULT_COLOR_SCHEME,
}: UspList): ReactElement => {
  const {
    configuration: { enableUspScroll },
    ppeContent,
    pageType,
  } = useStaticContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const { isMobile, isMobileOrTablet } = useMediaQueryContext();
  const {
    product: { salesDiscountsEndDate },
  } = usePDPContext();
  const { galleryZoomIn } = useGalleryContext();
  const { user } = useUserContext();
  const { language } = useAppContext();

  const [colorScheme, setColorScheme] = useState(initialColorScheme);
  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [ppeUspCountdown, setPpeUspCountdown] = useState<{
    countdown?: { endDate: Date };
    text?: string;
  }>({});
  const fullHeightGalleryZoomIn = isMobileOrTablet && hasStickyProductImageGallery && galleryZoomIn;

  const uspBarRef = useRef<HTMLDivElement>(null);

  const convertStringToDate = useCallback(
    (endDateStr: string, timezone: TimeZone) =>
      new Date(`${endDateStr.replace(' ', 'T')}${timeOffsets[timezone]}`),
    []
  );

  const usps = (usp as (USPCountdownSchema & USPItemSchema)[]).filter(item => {
    if (item.countdown && item.countdown.endDate) {
      const endTime = convertStringToDate(
        item.countdown.endDate,
        item.countdown.timezone || 'UTC+2 (CEST)'
      );
      const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/g;

      if (
        !regex.test(item.countdown.endDate) ||
        Math.floor((endTime.getTime() - Date.now()) / 1000) < 0
      ) {
        return false;
      }

      return item.text && item.text !== '';
    }

    return item.text?.title && item.text?.title !== '';
  });

  useEffect(() => {
    if (!user || !language || !ppeContent || isVisible) {
      return;
    }

    const handlePpeJourney = (language: string) => {
      const { expirationDate, formattedValue, trafficSource } = user.personalisedDiscount ?? {};

      if (!expirationDate || salesDiscountsEndDate || ppeUspCountdown.text) {
        setIsVisible(true);

        return;
      }

      const ppeJourneyContent = getPpeJourneyContent({
        ppeContent,
        trafficSource,
        expirationDate,
        formattedValue,
        language,
      });

      if (ppeJourneyContent?.uspCountdown) {
        setPpeUspCountdown(ppeJourneyContent.uspCountdown);

        if (ppeJourneyContent?.colorScheme === 'PPE') {
          setColorScheme(ppeJourneyContent.colorScheme);
        }

        setIsVisible(true);
      } else {
        setIsVisible(true);
      }
    };

    handlePpeJourney(language);
  }, [
    user,
    salesDiscountsEndDate,
    language,
    isVisible,
    ppeUspCountdown.text,
    ppeUspCountdown.countdown?.endDate,
    pageType,
    ppeContent,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (!(pageType === 'home' && ppeUspCountdown.text && uspBarRef.current)) {
        return;
      }

      if (window.scrollY === 0 && !uspBarRef.current.classList.contains('zeroHeight')) {
        uspBarRef.current.classList.add('zeroHeight');
      } else {
        uspBarRef.current.classList.remove('zeroHeight');
        uspBarRef.current.classList.add('sticky');
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageType, ppeUspCountdown.text]);

  useEffect(() => {
    const intervalId =
      enableUspScroll && isMobile && usps.length > 1
        ? setInterval(() => {
            setActiveIndex(activeIndex === usps.length - 1 ? 0 : activeIndex + 1);
          }, fullCycleTime)
        : undefined;

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [activeIndex, enableUspScroll, isMobile, usps.length]);

  return (
    <>
      {(usps.length !== 0 || ppeUspCountdown.text) && (
        <S.Wrapper
          ref={uspBarRef}
          $colorScheme={colorScheme}
          $isVisible={isVisible && !fullHeightGalleryZoomIn}
          $hasStickyProductImageGallery={hasStickyProductImageGallery}
          data-qubit-id="usp-bar"
          id={colorScheme === 'PPE' ? 'ppeBanner' : ''}
        >
          <S.USPBarList data-testid="usp-list" data-cs-capture="" data-qubit-id="usp-list">
            {ppeUspCountdown.countdown?.endDate && (
              <USPItemContainer suppressHydrationWarning className="ppe">
                <USPCountdown
                  endTime={ppeUspCountdown.countdown.endDate}
                  text={ppeUspCountdown.text}
                  colorScheme={colorScheme}
                  shouldAddLeadingZero
                />
              </USPItemContainer>
            )}
            {usps.map((item, index) => {
              if ('countdown' in item) {
                return (
                  !ppeUspCountdown.text && (
                    <USPItemContainer
                      suppressHydrationWarning
                      key={index}
                      url={item.link?.url}
                      target={item.link?.target}
                      className={`${
                        enableUspScroll && isMobile && usps.length > 1 ? 'scrollable' : ''
                      } ${
                        index === activeIndex && enableUspScroll && isMobile && usps.length > 1
                          ? 'active'
                          : ''
                      }`}
                    >
                      <USPCountdown
                        endTime={convertStringToDate(
                          item.countdown?.endDate ?? '',
                          item.countdown?.timezone ?? 'UTC+2 (CEST)'
                        )}
                        text={item.text}
                        colorScheme={colorScheme}
                      />
                    </USPItemContainer>
                  )
                );
              }

              return (
                <USPItemContainer
                  suppressHydrationWarning
                  key={index}
                  url={item.link?.url}
                  target={item.link?.target}
                  className={`${
                    !ppeUspCountdown.text && enableUspScroll && isMobile && usps.length > 1
                      ? 'scrollable'
                      : ''
                  } ${
                    !ppeUspCountdown.text &&
                    index === activeIndex &&
                    enableUspScroll &&
                    isMobile &&
                    usps.length > 1
                      ? 'active'
                      : ''
                  }`}
                  colorScheme={colorScheme}
                >
                  <USPItem
                    iconName={item.icon}
                    title={item.text?.title}
                    description={item.text?.description}
                    colorScheme={colorScheme}
                  />
                </USPItemContainer>
              );
            })}
          </S.USPBarList>
        </S.Wrapper>
      )}
    </>
  );
};
