import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { Icon } from '../../core/button/Button';
import { Button } from '../../core/button/Button';
import { media } from '../../core/styles';
import type { NavigationItem } from '~/utilities/graphql/codegen';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { LEAN_LOCALE_REGEX } from '~/utilities/constants/regex';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

type LinkType = 'Link' | 'Button';

export interface LinkProps {
  text: string;
  href: string;
  linkType?: LinkType;
  icon?: Icon;
}

const S = {
  Anchor: styled.a`
    ${({ theme }) => css`
      display: block;
      text-decoration: none;
      color: ${theme.colors.content.tertiary};
      white-space: nowrap;
      font-size: 13px;

      &:hover {
        color: ${theme.colors.content.hyperlink};
      }

      &:focus {
        color: ${theme.colors.content.tertiary};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          color: ${theme.colors.slate['600']};
          font-size: ${theme.font.size[3]};
          line-height: 1.4;
        `,
        [SiteType.Aaf]: css`
          color: ${theme.colors.content.secondary};
          font-size: ${theme.font.size[3]};
          line-height: 27px;

          &:focus {
            color: ${theme.colors.content.secondary};
          }
        `,
      })}
    `}
  `,

  Button: styled(Button)`
    ${({ theme }) => css`
      margin-top: 15px;
      width: 100%;
      line-height: 38px;

      @media ${media(theme).greaterThan('sm')} {
        line-height: 40px;
      }
    `}
  `,
};

export const GenericLink = ({
  label,
  target,
  url,
  styleModifier,
}: NavigationItem): ReactElement => {
  const { locale } = useAppContext();

  const addLocaleToUrl = (customUrl: string) => {
    if (customUrl.indexOf('http') === 0 || LEAN_LOCALE_REGEX.test(customUrl)) {
      return customUrl;
    }

    return `/${locale}${customUrl.indexOf('/') === 0 ? '' : '/'}${customUrl}`;
  };

  return (styleModifier ?? '').includes('footer-ctaLink') ? (
    <S.Button
      ordinal="tertiary"
      label={label ?? ''}
      href={addLocaleToUrl(url ?? '')}
      icon="pin"
      testId="footer-linklist-button"
      hardReload
    />
  ) : (
    <S.Anchor
      href={addLocaleToUrl(url ?? '')}
      {...(target === 'NEWWINDOW' ? { target: '_blank' } : {})}
    >
      {label}
    </S.Anchor>
  );
};
