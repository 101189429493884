import type { ReactElement } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { media } from '../../core/styles';
import { useAppContext } from '~/utilities/context/static/AppContext';
import HeartEmptyIcon from '../../core/icons/HeartEmptyIcon';
import { Typography } from '../../core/typography/Typography';
import { useWishlistContext } from '~/utilities/context/dynamic/WishlistContext';
import { useDataLayerContext } from '~/utilities/context/static/DataLayerContext';
import WishlistIcon from '../../core/icons/WishlistIcon';
import { HeaderButton } from '../HeaderStyle';
import { SiteType } from '~/utilities/graphql/codegen';

interface WishlistButtonProps {
  onWishlistHover: (hovered: boolean) => void;
  wishlistHovered: boolean;
}

const S = {
  WishListButton: styled.button`
    ${({ theme }) => HeaderButton[theme.siteType]}

    ${({ theme }) =>
      theme.siteType === SiteType.Gstar &&
      css`
        &:hover,
        &:focus {
          path {
            fill: ${({ theme }) => theme.colors.content.component.header.hover};
          }

          span {
            color: ${({ theme }) => theme.colors.content.component.header.hover};
          }
        }
      `};
  `,

  Amount: styled(Typography)`
    color: ${({ theme }) => theme.colors.content.component.header.color};
    ${({ theme }) =>
      theme.siteType === SiteType.Aaf &&
      css`
        font-weight: 400;
      `}
  `,

  IconWrapper: styled.span`
    ${({ theme }) => css`
      display: inline-block;
      width: ${theme.size[4]};
      height: ${theme.size[4]};

      @media ${media(theme).greaterThan('sm')} {
        width: ${theme.siteType === SiteType.Gstar ? '16px' : '18px'};
        height: ${theme.siteType === SiteType.Gstar ? '16px' : '18px'};
      }
    `}
  `,
};

export const WishListButton = ({
  onWishlistHover,
  wishlistHovered,
}: WishlistButtonProps): ReactElement => {
  const { miniWishlist } = useWishlistContext();
  const { pushToDataLayer } = useDataLayerContext();
  const theme = useTheme();
  const { isGStar } = useAppContext();

  const handleClick = () => {
    window.location.href = '/wishlist';
  };

  return (
    <S.WishListButton
      onMouseEnter={() => {
        onWishlistHover(true);
        pushToDataLayer({
          events: {
            category: 'wishlist',
            action: 'hover',
            label: 'open mini-wishlist',
          },
          event: 'mini-wishlist-open',
        });
      }}
      onMouseLeave={() => onWishlistHover(false)}
      onClick={handleClick}
      aria-label={`Wishlist contains ${miniWishlist?.products?.length || '0'} ${
        (miniWishlist?.products?.length || 0) === 1 ? 'item' : 'items'
      }`}
      aria-haspopup="true"
      aria-controls="wishlistHeader"
      aria-expanded={wishlistHovered}
      data-cs-capture=""
    >
      {isGStar && (
        <S.IconWrapper data-testid="wishlist-icon">
          <HeartEmptyIcon color={theme.colors.content.component.header.color} />
        </S.IconWrapper>
      )}
      {!isGStar && (
        <S.IconWrapper data-testid="wishlist-icon">
          <WishlistIcon color={theme.colors.content.component.header.color} />
        </S.IconWrapper>
      )}
      {miniWishlist?.products && miniWishlist.products.length > 0 && (
        <S.Amount component="span" variant="title" testId="wishlist-count" data-cs-capture="">
          {miniWishlist.products?.length}
        </S.Amount>
      )}
    </S.WishListButton>
  );
};
