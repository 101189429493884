import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Typography } from '../core/typography/Typography';
import { Checkbox, InputField } from '../core/form';
import { Button } from '../core/button/Button';
import { media } from '../core/styles';
import type { GlobalNewsletter as GlobalNewsletterSchema } from '../../../amplienceTypes/schemas/imported/global-newsletter-schema';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import {
  EntryPoint,
  NewsletterSubscribeSuccessType,
  SubscribeStatusCode,
} from '../../../utilities/graphql/codegen';
import { PRIVACY_POLICY_VARIABLE } from '../../../utilities/constants/amplienceVariables';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { getFormSchema } from '../../../utilities/constants/formSchema';
import { subscribeToGstar, getSiteStyles } from '../../../utilities/helpers';
import { SiteType } from '~/utilities/graphql/codegen';

type NewsletterType = {
  email: string;
  preferences: string;
  notification: string;
  updates: boolean;
};

const S = {
  Wrapper: styled.div`
    ${({ theme }) => css`
      display: flex;
      flex-direction: column;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          gap: ${theme.size[4]};

          @media ${media(theme).greaterThan('xl')} {
            max-width: 48%;
          }

          .newsletterTitle {
            text-transform: uppercase;
            text-align: left;
            line-height: 1.4;
          }
        `,
        [SiteType.Aaf]: css`
          align-items: start;

          & h2 {
            line-height: 100%;
            padding: ${theme.size[4]} ${theme.size[0]};
          }

          @media ${media(theme).greaterThan('sm')} {
            max-width: 60%;
          }
        `,
      })}
    `}
  `,

  Form: styled.form`
    ${({ theme }) => css`
      max-width: 550px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media ${media(theme).lessThan('sm')} {
        max-width: 100%;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          max-width: 100%;
        `,
        [SiteType.Aaf]: css`
          margin: 0;
        `,
      })}
    `}
  `,

  EmailSubscribe: styled.div`
    ${({ theme }) => css`
      padding: ${theme.size[4]} ${theme.size[0]};
      display: flex;

      @media ${media(theme).greaterThan('sm')} {
        flex-direction: row;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          flex-direction: row;
          padding: 0;

          &:checked + span::before {
            color: ${theme.colors.slate['300']};
          }
        `,
      })}
    `}
  `,

  Email: styled.div`
    ${({ theme }) => css`
      width: 100%;
      box-sizing: border-box;
      margin-right: 16px;

      @media ${media(theme).greaterThan('sm')} {
        max-width: 100%;
        margin-right: 16px;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          margin: 0;

          @media ${media(theme).greaterThan('sm')} {
            margin: 0;
          }

          input {
            font-size: ${theme.font.size[3]};
            border: ${theme.borders.width.xs} solid ${theme.colors.border.subtle};
            height: 40px;

            &::placeholder {
              color: ${theme.colors.slate['600']};
            }

            &:focus {
              border-color: ${theme.colors.border.subtle};
            }

            &:checked + span::before {
              color: ${theme.colors.slate['300']};
            }

            &:focus + span::before {
              color: ${theme.colors.slate['300']};
            }
          }
        `,
        [SiteType.Aaf]: css`
          input {
            &::placeholder {
              color: ${theme.colors.content.secondary};
            }

            &:focus {
              &::placeholder {
                color: #a1a1a0;
              }
            }
          }
        `,
      })}
    `}
  `,

  Description: styled.div`
    ${({ theme }) => css`
      text-align: left;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('sm')} {
            margin-top: 0;
          }
        `,
        [SiteType.Aaf]: css`
          & span {
            line-height: 136%; /* add padding */
            height: 34px;
            padding-top: ${theme.size[1]};
            color: ${theme.colors.content.secondary};
          }
        `,
      })}
    `}
  `,

  SubmitBtnContainer: styled.div`
    ${({ theme }) => css`
      display: contents;

      @media ${media(theme).greaterThan('sm')} {
        display: contents;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Aaf]: css`
          display: none;
        `,
      })}
    `}
  `,

  SubmitBtn: styled(Button)`
    ${({ theme }) => css`
      font-size: 13px;
      padding: 0 10px;
      height: 48px;

      @media ${media(theme).greaterThan('sm')} {
        flex: 1;
        height: 40px;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          font-size: ${theme.font.size[3]};
          font-weight: normal;
          padding: ${theme.size[3]} ${theme.size[4]};
          height: 40px;
          min-width: fit-content;
          margin-left: 10px;
          display: inline-block;
          line-height: 0;
          text-transform: none;
        `,
        [SiteType.Aaf]: css`
          margin-top: 8px;
        `,
      })}
    `}
  `,
  SubmitBtnArrowContainer: styled.div`
    ${({ theme }) => css`
      display: contents;

      @media ${media(theme).greaterThan('sm')} {
        display: none;
      }
    `}
  `,

  SubmitBtnArrow: styled(Button)`
    padding: 0 10px;
    line-height: 38px;
  `,

  NotificationCheckBox: styled.div<{ $visible: boolean }>`
    ${({ theme, $visible }) => css`
      height: ${$visible ? 'auto' : 0};
      overflow: hidden;
      opacity: ${$visible ? 1 : 0};
      transition: opacity 0.2s linear;

      @media (prefers-reduced-motion) {
        transition: none;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          label {
            padding: 16px 0 0;

            input {
              &:checked + span::before {
                border: ${theme.borders.width.xs} solid ${theme.colors.slate['300']};
                font-size: ${theme.font.size[3]};
                line-height: 18px;
              }

              &:focus + span::before {
                border-width: ${theme.borders.width.xs};
              }
            }

            span::before {
              border: ${theme.borders.width.xs} solid ${theme.colors.slate['300']};
              width: 16px;
              height: 16px;
              top: 18px;
            }
          }
        `,
      })}
    `}
  `,

  PrivacyPolicyLink: styled.a`
    ${({ theme }) => css`
      color: ${theme.colors.content.tertiary};
      cursor: pointer;

      &:hover,
      &:focus {
        color: ${theme.colors.content.primary};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Aaf]: css`
          color: ${theme.colors.content.secondary};
        `,
      })}
    `}
  `,

  SubscribeResponse: styled.div`
    ${({ theme }) => css`
      padding: 10px 0;
      color: ${theme.colors.content.tertiary};
      font-size: 13px;
      line-height: 20px;
      text-align: center;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          text-align: left;
          padding: 0;
        `,
        [SiteType.Aaf]: css`
          color: ${theme.colors.content.secondary};
        `,
      })}
    `}
  `,

  Subtitle: styled(Typography)`
    ${({ theme }) => css`
      font-size: 13px;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          color: ${theme.colors.slate['600']};
          font-size: ${theme.font.size[3]};
          line-height: 1.4;
        `,
        [SiteType.Aaf]: css`
          font-size: ${theme.font.size[2]};
        `,
      })}
    `}
  `,
  PrivacyPolicyEl: styled(Typography)`
    ${({ theme }) => css`
      color: ${theme.colors.content.secondary};
      font-size: 13px;

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          font-size: ${theme.font.size[3]};
          color: ${theme.colors.slate['600']};
        `,
        [SiteType.Aaf]: css`
          font-size: ${theme.font.size[2]};
        `,
      })}
    `}
  `,
};

export const Newsletter = ({
  title,
  subtitle,
  bodyTitle,
  emailPlaceholder,
  subscribeBtn,
  description,
  privacyPolicy,
  newsletterSuccessMessageNewUser,
  newsletterSuccessMessageExistingUser,
}: GlobalNewsletterSchema): ReactElement => {
  const { t } = useTranslation('common');

  const [newsletterSubscribeSuccessType, setNewsletterSubscribeSuccessType] =
    useState<NewsletterSubscribeSuccessType>();
  const [visiblePrivacyCheckbox, setVisiblePrivacyCheckbox] = useState(false);
  const { country, pushToContentSquare, isGStar } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { formErrorLabels } = useStaticContext();
  const NewsletterSchema = getFormSchema({
    emailRequiredLabel:
      formErrorLabels?.emailRequiredLabel || t('globalFormErrorLabels.emailRequiredLabel'),
    emailInvalidLabel:
      formErrorLabels?.emailInvalidLabel || t('globalFormErrorLabels.emailInvalidLabel'),
    preferencesRequiredLabel:
      formErrorLabels?.preferencesRequiredLabel ||
      t('globalFormErrorLabels.preferencesRequiredLabel'),
    notificationRequiredLabel:
      formErrorLabels?.notificationRequiredLabel ||
      t('globalFormErrorLabels.notificationRequiredLabel'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    trigger,
    getValues,
    watch,
  } = useForm<NewsletterType>();

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (name === 'email' && type === 'change') {
        return;
      }

      if (name === 'notification') {
        await trigger('notification');
      }

      setVisiblePrivacyCheckbox(
        !!(
          errors.email ||
          errors.preferences ||
          !(name !== 'notification' && !errors.notification) ||
          value.notification ||
          value.email
        )
      );
    });

    return () => subscription.unsubscribe();
  }, [errors.email, errors.notification, errors.preferences, trigger, watch]);

  const handleSubscribe: SubmitHandler<NewsletterType> = async values => {
    const entryPointId: EntryPoint = EntryPoint.Footer;
    const subscriptionPageTopic = 'topic_bf20Q4';

    try {
      const response = await subscribeToGstar({
        isNewsLetterSubscription: true,
        entryPoint: entryPointId,
        email: values.email,
        preferredCategory: values.preferences,
      });

      if (response?.subscribeStatusCode === SubscribeStatusCode.Success) {
        setNewsletterSubscribeSuccessType(response.newsletterSubscribeSuccessType);

        pushToDataLayer({
          events: {
            category: 'newsletter',
            action: 'subscribe',
            label: `NEWSLETTER_SUBSCRIPTION~${subscriptionPageTopic}`.toLowerCase(),
          },
          page: {
            countryCode: country.toUpperCase(),
          },
          event: 'newsletter-subscribe',
        });

        pushToContentSquare('trackPageEvent', 'Action | Subscribed to newsletter');
      } else {
        // eslint-disable-next-line no-console
        console.log(response?.errors);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const privacyPolicyEl = (
    <S.PrivacyPolicyEl as="span">
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[0]}
      <S.PrivacyPolicyLink href="/help-info/privacy-policy">
        {privacyPolicy || t('globalNewsletter.privacyPolicy')}
      </S.PrivacyPolicyLink>
      {(description || t('globalNewsletter.description') || '').split(PRIVACY_POLICY_VARIABLE)[1]}
    </S.PrivacyPolicyEl>
  );

  return (
    <S.Wrapper data-testid="newsletter-component" data-cs-capture="">
      <Typography
        component="h2"
        variant="title"
        alignment="left"
        testId="newsletter-title"
        data-cs-capture=""
        className={isGStar ? 'newsletterTitle' : ''}
      >
        {title || t('globalNewsletter.title')}
      </Typography>
      <S.Description data-testid="newsletter-intro" data-cs-capture="">
        <S.Subtitle
          component="span"
          variant="body"
          alignment={isGStar ? 'center' : 'left'}
          data-cs-capture=""
        >
          {subtitle || t(isGStar ? 'globalNewsletter.subtitle' : 'globalNewsletter.subtitleAaf')}
          &nbsp;
          {(isGStar && bodyTitle) || t('globalNewsletter.bodyTitle')}
        </S.Subtitle>
      </S.Description>
      {newsletterSubscribeSuccessType ? (
        <S.SubscribeResponse
          dangerouslySetInnerHTML={{
            __html:
              (newsletterSubscribeSuccessType === NewsletterSubscribeSuccessType.NewCustomer
                ? newsletterSuccessMessageNewUser ||
                  t('globalNewsletter.newsletterSuccessMessageNewUser')
                : newsletterSuccessMessageExistingUser ||
                  t('globalNewsletter.newsletterSuccessMessageExistingUser')) || '',
          }}
        />
      ) : (
        <S.Form onSubmit={handleSubmit(handleSubscribe)}>
          <S.EmailSubscribe>
            <S.Email>
              <InputField
                placeholder={emailPlaceholder || t('globalNewsletter.emailPlaceholder')}
                name="email"
                register={register}
                required={NewsletterSchema.email.required}
                pattern={NewsletterSchema.email.pattern}
                showError
                error={errors.email && errors.email.message}
                testId="newsletter-email"
                onFocus={() => setVisiblePrivacyCheckbox(true)}
              />
            </S.Email>
            <S.SubmitBtnContainer>
              <S.SubmitBtn
                label={subscribeBtn || t('globalNewsletter.subscribeBtn')}
                type="submit"
                disabled={isSubmitting}
                testId="newsletter-submit"
                data-cs-capture=""
                icon={!isGStar ? 'sendArrow' : undefined}
                ordinal={isGStar ? 'primary' : 'highlighted'}
                leftIcon={isGStar}
                textTransform={isGStar ? 'lowercase' : 'uppercase'}
              />
            </S.SubmitBtnContainer>
            {!isGStar && (
              <S.SubmitBtnArrowContainer>
                <S.SubmitBtnArrow
                  type="submit"
                  disabled={isSubmitting}
                  testId="newsletter-submit"
                  data-cs-capture=""
                  icon="sendArrow"
                  ordinal="icon"
                />
              </S.SubmitBtnArrowContainer>
            )}
          </S.EmailSubscribe>
          {privacyPolicyEl && (
            <S.NotificationCheckBox $visible={visiblePrivacyCheckbox}>
              <Checkbox
                label={privacyPolicyEl}
                newsletter
                name="notification"
                register={register}
                required={NewsletterSchema.notification.required}
                error={errors.notification && errors.notification.message}
                testId="newsletter-privacy-policy"
                data-cs-capture=""
                isChecked={getValues().notification as 'true' | 'false'}
              />
            </S.NotificationCheckBox>
          )}
        </S.Form>
      )}
    </S.Wrapper>
  );
};
