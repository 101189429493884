import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { ChangeEvent, KeyboardEvent, MouseEvent, ReactElement } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import type { FieldValues, SubmitHandler, UseFormRegister } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { InsightsMethodMap } from 'search-insights';
import styled, { css } from 'styled-components';
import fetchService from '../../../../utilities/helpers/fetchService';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import { buildApiUrl, removeLocaleFromFullUrl } from '../../../../utilities/parsers';
import { isSearch } from '../../../../utilities/ssr';
import type { SearchTrackingType } from '../../../../utilities/vendors';
import { parseInternalSearchDataLayer } from '../../../../utilities/vendors';
import { InputField } from '../../core/form';
import { media } from '../../core/styles';
import { useAlgoliaInsights } from '../../vendors/useAlgoliaInsights';
import type { SearchResultPanelProps } from './SearchResultPanel';
import { ToggleSearch } from './ToggleSearch';
import type { SearchResult } from './types';
import { SiteType } from '~/utilities/graphql/codegen';
import { getSiteStyles } from '~/utilities/helpers';

export interface SearchBoxInputType extends FieldValues {
  keyword: string;
}

interface SearchBoxProps {
  placeholder?: string;
  searchBoxActive: boolean;
  setSearchBoxActive: (value: boolean) => void;
}

const SearchResultPanel = dynamic<SearchResultPanelProps>(
  () => import('./SearchResultPanel').then(mod => mod.SearchResultPanel),
  { ssr: false }
);

const S = {
  Form: styled.form<{ $searchBoxActive: boolean; $sideNavOpened: boolean }>`
    ${({ theme, $searchBoxActive, $sideNavOpened }) => css`
      position: absolute;
      right: 0;
      top: 0;
      overflow: visible;
      z-index: -1;
      visibility: ${$searchBoxActive ? 'visible' : 'hidden'};
      transform: ${$searchBoxActive
        ? `translateY(var(--top-nav-height)) translateZ(0)`
        : `translateY(0) translateZ(0)`};
      transition: ${$searchBoxActive
        ? `transform .15s ease-in,visibility 0s linear 0s`
        : `transform .15s ease-out,visibility 0s linear .15s`};

      @media (prefers-reduced-motion) {
        transition: none;
      }

      @media ${media(theme).between('xs', 'xl')} {
        left: ${$sideNavOpened ? '314px' : '0'};
      }

      @media ${media(theme).lessThan('xs')} {
        visibility: ${$sideNavOpened ? 'hidden' : 'visible'};
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          position: fixed;
          display: inline-block;

          @media ${media(theme).greaterThan('xl')} {
            position: static;
            visibility: visible;
            border-left: 1px solid ${theme.colors.border.mid};
            border-right: 1px solid ${theme.colors.border.mid};
            z-index: 1;
            width: 240px;
            right: 310px;
            transform: translateY(0) translateZ(0);
            left: auto;
            box-sizing: border-box;
          }
        `,
        [SiteType.Aaf]: css`
          @media ${media(theme).greaterThan('xl')} {
            left: auto;
            right: 0;
            width: 480px;
          }
        `,
      })}
    `}
  `,

  Input: styled(InputField)`
    ${({ theme }) => css`
      input {
        border: none;
        background: ${theme.colors.content.component.header.modal.base};
        color: ${theme.colors.content.component.header.modal.color};
        width: 100%;
        height: 54px;
        padding: 0 42px;
        text-transform: none;

        ${getSiteStyles(theme.siteType, {
          [SiteType.Gstar]: css`
            @media ${media(theme).greaterThan('xl')} {
              background-color: transparent;
              color: ${theme.colors.content.component.header.color};
              font-weight: normal;
              font-size: 14px;
              line-height: var(--top-nav-height);
              padding-right: 15px;
              position: relative;
              z-index: 114;
              border: none;

              &::placeholder {
                color: ${theme.colors.slate['500']};
              }
            }
          `,
          [SiteType.Aaf]: css`
            border-top: 1px solid ${theme.colors.border.subtle};
            border-bottom: 1px solid ${theme.colors.border.subtle};

            &:focus {
              border-color: ${theme.colors.border.subtle};
            }
          `,
        })}
      }
    `}
  `,

  ToggleSearch: styled(ToggleSearch)<{ $searchBoxActive: boolean }>`
    ${({ theme, $searchBoxActive }) => css`
      position: absolute;
      left: 0;
      top: 0;
      width: 42px;
      justify-content: center;
      z-index: 1;

      path {
        fill: ${theme.siteType === SiteType.Gstar
          ? theme.colors.content.component.header.color
          : theme.colors.content.component.header.base};
      }

      @media ${media(theme).greaterThan('xl')} {
        display: flex;
        height: 100%;
        justify-content: center;
        background: ${$searchBoxActive
          ? `transparent`
          : theme.colors.content.component.header.base};
        z-index: 115;

        span {
          width: 16px;
        }
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('xl')} {
            background: transparent;

            path {
              fill: ${theme.colors.content.component.header.color};
            }
          }
        `,
        [SiteType.Aaf]: css`
          path {
            fill: ${theme.colors.content.component.header.color};
          }

          @media ${media(theme).greaterThan('xl')} {
            path {
              fill: ${theme.colors.content.component.header.color};
            }
          }
        `,
      })}
    `}
  `,

  CloseButton: styled.button`
    ${({ theme }) => css`
      position: absolute;
      top: 0;
      right: 0;
      width: 42px;
      height: var(--top-nav-height);
      border: none;
      outline: none;
      cursor: pointer;

      &::before {
        font-family: gstar-icons;
        font-size: 16px;
        text-align: left;
      }

      ${getSiteStyles(theme.siteType, {
        [SiteType.Gstar]: css`
          @media ${media(theme).greaterThan('xl')} {
            display: none;
          }

          &::before {
            content: '\\F13F';
          }
        `,
        [SiteType.Aaf]: css`
          &::before {
            content: '\\F259';
          }
        `,
      })}
    `}
  `,
};

const SearchBoxComponent = ({
  placeholder,
  searchBoxActive = false,
  setSearchBoxActive,
}: SearchBoxProps): ReactElement => {
  const { pushToAlgoliaWithQueryId } = useAlgoliaInsights();
  const [searchResult, setSearchResult] = useState<SearchResult>({});
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [keyword, setKeyword] = useState('');

  const router = useRouter();

  const { locale } = useAppContext();
  const { sideNavOpened } = useNavActionContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalTopNavigation.searchBox' });

  const { pushToDataLayer } = useDataLayerContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SearchBoxInputType>();

  const handleChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const newKeyword = evt.target.value;

    setKeyword(newKeyword);

    try {
      if (newKeyword) {
        const url = buildApiUrl(`/suggest?search=${newKeyword}&_=${new Date().getTime()}`, locale);
        const response = await fetchService.get<SearchResult>(url);
        const searchData: SearchResult = response.data;

        setSearchResult(searchData);
        setActiveItem(null);
      } else {
        setSearchResult({});
      }
    } catch (error) {
      // TODO Error Handling
    }
  };

  const redirectToSearchResult = useCallback(
    (trackingOption: SearchTrackingType, isItem = true) => {
      try {
        const suggestionCount = searchResult.suggestions?.length || 0;

        if (activeItem === null || !isItem) {
          if (keyword.trim() !== '') {
            window.location.href = `/${locale}/search?q=${keyword}`;
            pushToDataLayer(parseInternalSearchDataLayer(trackingOption, keyword));
            // TODO come back after FES search page is live
            // router.push(`/search?q=${keyword}`);
          }
        } else if (searchResult.suggestions && activeItem < suggestionCount) {
          window.location.href = `/${locale}/search?q=${searchResult.suggestions[activeItem].term}`;
          pushToDataLayer(
            parseInternalSearchDataLayer(
              trackingOption,
              keyword,
              searchResult.suggestions[activeItem].term
            )
          );
          // TODO come back after FES search page is live
          // router.push(`/search?q=${searchResult.suggestions[activeItem].term}`);
        } else if (searchResult.products) {
          const { algoliaObjectId, url } = searchResult.products[activeItem - suggestionCount];
          const productUrl = removeLocaleFromFullUrl(url);
          const event: keyof InsightsMethodMap = searchResult?.algoliaQueryId
            ? 'clickedObjectIDsAfterSearch'
            : 'clickedObjectIDs';
          const eventName = searchResult?.algoliaQueryId
            ? 'SUGGESTION_clickedObjectIDsAfterSearch'
            : 'SUGGESTION_clickedObjectIDs';

          if (searchResult?.algoliaQueryId) {
            localStorage.setItem('algoliaQueryID', searchResult?.algoliaQueryId || '');
          }

          pushToAlgoliaWithQueryId(event, eventName, {
            objectIDs: [algoliaObjectId],
            positions: [activeItem + 1],
          });

          router.push(`/${productUrl}`);
        }
      } catch (e) {
        // TODO Error Handling
      }
    },
    [
      activeItem,
      keyword,
      locale,
      pushToDataLayer,
      router,
      searchResult.products,
      searchResult.suggestions,
      pushToAlgoliaWithQueryId,
      searchResult?.algoliaQueryId,
    ]
  );

  const handleKeydown = (evt: KeyboardEvent<HTMLInputElement>) => {
    try {
      const suggestionCount = searchResult.suggestions?.length || 0;
      const productCount = searchResult.products?.length || 0;

      if (evt.key === 'ArrowUp') {
        setActiveItem(
          activeItem === 0 || !activeItem ? suggestionCount + productCount - 1 : activeItem - 1
        );
      } else if (evt.key === 'ArrowDown') {
        setActiveItem(
          activeItem === suggestionCount + productCount - 1 || activeItem === null
            ? 0
            : activeItem + 1
        );
      } else if (evt.key === 'Enter') {
        evt.preventDefault();
        redirectToSearchResult('enter', activeItem !== null);
      } else if (evt.key === 'Escape') {
        setSearchBoxActive(false);
      }
    } catch (e) {
      // TODO Error Handling
    }
  };

  const subscribe: SubmitHandler<SearchBoxInputType> = values => values;

  useEffect(() => {
    if (isSearch(router.pathname)) {
      const query = (router.query.q || '') as string;

      setKeyword(query);
    }
  }, [router.pathname, router.query.q]);

  return (
    <S.Form
      onSubmit={handleSubmit(subscribe)}
      $searchBoxActive={searchBoxActive}
      suppressHydrationWarning
      $sideNavOpened={sideNavOpened}
      onFocus={() => setSearchBoxActive(true)}
      onBlur={() => setSearchBoxActive(false)}
      tabIndex={-1}
    >
      <S.ToggleSearch
        label="Search"
        type="submit"
        disabled={isSubmitting}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          redirectToSearchResult('click', false);
        }}
        $searchBoxActive={searchBoxActive}
      />
      <S.Input
        label=""
        placeholder={placeholder || t('placeholder')}
        id="searchBox_input"
        name="keyword"
        register={register as unknown as UseFormRegister<FieldValues>}
        error={errors.keyword && errors.keyword.message}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        $searchBoxActive={searchBoxActive}
        ariaExpanded={searchBoxActive}
        autoComplete="off"
        testId="search-input"
        data-cs-capture=""
        role="combobox"
        value={keyword}
      />
      <S.CloseButton disabled={isSubmitting} onClick={() => setSearchBoxActive(false)} />
      {searchBoxActive && (
        <SearchResultPanel
          searchResult={searchResult}
          keyword={keyword}
          searchBoxActive={searchBoxActive}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          redirectToSearchResult={redirectToSearchResult}
        />
      )}
    </S.Form>
  );
};

export const SearchBox = memo(SearchBoxComponent, isEqual);
